import store from '@/store';

export default class SubscriptionPolicy {
    static viewTab(planName) {
        let user =
            Object.keys(store.getters['user/user']).length === 0
                ? JSON.parse(localStorage.getItem('user'))
                : store.getters['user/user'];
        let company = store.getters['company/company'] || JSON.parse(localStorage.getItem('company'));
        const subType = {
            basicPlan: ['Basic', 'Premium', 'Enterprise', 'Development'],
            premiumPlan: ['Premium', 'Enterprise', 'Development'],
            enterprisePlan: ['Enterprise', 'Development'],
            developmentPlan: ['Development'],
        };

        if (user && company) {
            try {
                const roles = user.roles_access;
                const subscriptionCheck = this.subscription(company.subscription, subType[planName]);
                return (roles.is_admin || roles.is_manager) && subscriptionCheck;
            } catch (error) {
                console.error(error);
                return false;
            }
        }
    }

    static subscription(subscription, names) {
        if (subscription) {
            if (subscription.attributes.active) {
                return names.includes(subscription.attributes.subscription.name);
            }
        }
        return false;
    }
}

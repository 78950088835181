import ClientService from './services/client.service';
import router from '@/router';

export const ModuleConfig = {
    routerPrefix: 'client',
    loadOrder: 21,
    moduleName: 'Client',
};

export function init(context) {
    context.addRedirectPath({ path: '/client/view/', withID: true });

    const checkAccess = user => {
        if ((user && user.roles_access && user.roles_access.is_admin) || user.roles_access.is_manager) {
            return true;
        }
        return false;
    };

    const crud = context.createCrud('clients.crud-title', 'clients', ClientService);

    const crudViewRoute = crud.view.getViewRouteName();
    const crudEditRoute = crud.edit.getEditRouteName();
    const crudNewRoute = crud.new.getNewRouteName();

    const navigation = { view: crudViewRoute, edit: crudEditRoute, new: crudNewRoute };

    crud.view.addToMetaProperties('titleCallback', ({ values }) => values.name, crud.view.getRouterConfig());
    crud.view.addToMetaProperties('navigation', navigation, crud.view.getRouterConfig());

    crud.new.addToMetaProperties('permissions', 'clients/create', crud.new.getRouterConfig());
    crud.new.addToMetaProperties('navigation', navigation, crud.new.getRouterConfig());

    crud.edit.addToMetaProperties('permissions', 'clients/edit', crud.edit.getRouterConfig());

    const grid = context.createGrid('clients.grid-title', 'clients', ClientService, {});
    grid.addToMetaProperties('navigation', navigation, grid.getRouterConfig());

    const fieldsToShow = [
        {
            label: 'field.company_name',
            key: 'name',
            render: (h, data) => {
                return h('span', data.values.name);
            },
        },
        {
            label: 'field.description',
            key: 'description',
            render: (h, data) => {
                return h('span', data.values.description);
            },
        },
        {
            label: 'field.company_number',
            key: 'company_number',
            render: (h, data) => {
                return h('span', data.values.company_number);
            },
        },
        {
            label: 'field.street_address',
            key: 'street_address',
            render: (h, data) => {
                return h('span', data.values.street_address);
            },
        },
        {
            label: 'field.zip',
            key: 'zip',
            render: (h, data) => {
                return h('span', data.values.zip);
            },
        },
        {
            label: 'field.city',
            key: 'city',
            render: (h, data) => {
                return h('span', data.values.city);
            },
        },
        {
            label: 'field.country',
            key: 'country',
            render: (h, data) => {
                return h('span', data.values.country);
            },
        },
        {
            label: 'field.url',
            key: 'url',
            render: (h, data) => {
                return h('span', data.values.url);
            },
        },
        {
            label: 'field.user_name',
            key: 'full_name',
            render: (h, data) => {
                return h('span', data.values.full_name);
            },
        },
        {
            label: 'field.email',
            key: 'email',
            render: (h, data) => {
                return h('span', data.values.email);
            },
        },
        {
            label: 'field.position_in_company',
            key: 'position_in_company',
            render: (h, data) => {
                return h('span', data.values.position_in_company);
            },
        },
        {
            label: 'field.phone',
            key: 'phone',
            render: (h, data) => {
                return h('span', data.values.phone);
            },
        },
    ];

    const fieldsToFill = [
        {
            key: 'id',
            displayable: false,
        },
        {
            label: 'field.company_name',
            key: 'name',
            type: 'text',
            placeholder: 'field.name',
            required: true,
        },
        {
            label: 'field.description',
            key: 'description',
            type: 'text',
            placeholder: 'field.description',
        },
        {
            label: 'field.company_number',
            key: 'company_number',
            type: 'text',
            placeholder: 'field.company_number',
            required: true,
        },
        {
            label: 'field.street_address',
            key: 'street_address',
            type: 'text',
            placeholder: 'field.street_address',
            required: true,
        },
        {
            label: 'field.zip',
            key: 'zip',
            type: 'text',
            placeholder: 'field.zip',
            required: true,
        },
        {
            label: 'field.city',
            key: 'city',
            type: 'text',
            placeholder: 'field.city',
            required: true,
        },
        {
            label: 'field.country',
            key: 'country',
            type: 'text',
            placeholder: 'field.country',
            required: true,
        },
        {
            label: 'field.url',
            key: 'url',
            type: 'text',
            placeholder: 'field.url',
        },
        {
            label: 'field.user_name',
            key: 'full_name',
            type: 'text',
            placeholder: 'field.full_name',
            required: true,
        },
        {
            label: 'field.email',
            key: 'email',
            type: 'text',
            placeholder: 'field.email',
            required: true,
        },
        {
            label: 'field.position_in_company',
            key: 'position_in_company',
            type: 'text',
            placeholder: 'field.position_in_company',
            required: true,
        },
        {
            label: 'field.phone',
            key: 'phone',
            type: 'text',
            placeholder: 'field.phone',
            required: true,
        },
    ];

    crud.view.addField(fieldsToShow);
    crud.new.addField(fieldsToFill);
    crud.edit.addField(fieldsToFill);

    grid.addColumn([
        {
            title: 'field.user_name',
            key: 'full_name',
            render(h, { item }) {
                return h('span', item.attributes.full_name);
            },
        },
        {
            title: 'field.email',
            key: 'email',
            render(h, { item }) {
                return h('span', item.attributes.email);
            },
        },
        {
            title: 'field.position_in_company',
            key: 'position_in_company',
            render(h, { item }) {
                return h('span', item.attributes.position_in_company);
            },
        },
    ]);

    grid.addAction([
        {
            title: 'control.view',
            icon: 'icon-eye',
            onClick: (router, { item }, context) => {
                context.onView(item.attributes);
            },
            renderCondition({ $store }) {
                return checkAccess($store.getters['user/user']);
            },
        },
        {
            title: 'control.edit',
            icon: 'icon-edit',
            onClick: (router, { item }, context) => {
                context.onEdit(item.attributes);
            },
            renderCondition({ $store }) {
                return checkAccess($store.getters['user/user']);
            },
        },
        {
            title: 'control.delete',
            actionType: 'error',
            icon: 'icon-trash-2',
            onClick: (router, { item }, context) => {
                context.onDelete(item.attributes);
            },
            renderCondition({ $store }) {
                return checkAccess($store.getters['user/user']);
            },
        },
    ]);

    grid.addPageControls([
        {
            label: 'control.create',
            type: 'primary',
            icon: 'icon-edit',
            onClick: ({ $router }) => {
                $router.push({ name: crudNewRoute });
            },
            renderCondition({ $store }) {
                return checkAccess($store.getters['user/user']);
            },
        },
    ]);

    context.addRoute(crud.getRouterConfig());
    context.addRoute(grid.getRouterConfig());

    context.addNavbarEntry({
        label: 'navigation.clients',
        to: {
            path: '/client',
        },
        displayCondition: () => {
            const user = JSON.parse(localStorage.getItem('user'));
            return checkAccess(user);
        },
    });

    router.beforeEach((to, from, next) => {
        const user = JSON.parse(localStorage.getItem('user'));

        if (to.path.startsWith('/client')) {
            if (checkAccess(user)) {
                next();
            } else {
                next('/forbidden');
            }
        } else {
            next();
        }
    });

    context.addLocalizationData({
        en: require('./locales/en'),
        ru: require('./locales/ru'),
        ua: require('./locales/ua'),
        pl: require('./locales/pl'),
        de: require('./locales/de'),
        fr: require('./locales/fr'),
    });

    return context;
}

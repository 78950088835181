export default class StatusPolicy {
    static viewAny(user) {
        return user.roles_access.is_admin;
    }

    static create(user) {
        return user.roles_access.is_admin;
    }

    static update(user, _model) {
        return user.roles_access.is_admin;
    }

    static delete(user, _model) {
        return user.roles_access.is_admin;
    }
}

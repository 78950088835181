import store from '@/store';

export default class RolePolicy {
    static haveRole(rolesName) {
        let user =
            Object.keys(store.getters['user/user']).length === 0
                ? JSON.parse(localStorage.getItem('user'))
                : store.getters['user/user'];

        const userRoles = user.roles.map(role => role.attributes.name);
        return userRoles.some(name => rolesName.includes(name));
    }
}
